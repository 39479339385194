<style scoped>
/* Set the page size to A4 and the orientation to landscape */
@page {
  size: letter portrait;
}

/* Set the font size to 12px for printing */
@media print {
  body {
    font-size: 12px;
  }

  .page-break {
    page-break-before: always;
    counter-reset: page;
  }
}

#logo {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 200px;
  height: 100px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

/* td,
th.bordered {
  border: 1px solid #000000;
  text-align: left;
  padding: 8px;
} */

th.no-border {
  border: none;
}

.remove_border tr th {
  border: none;
}

#table-list {
  margin-top: 80px;
}

.info {
  margin-top: 20px;
  width: 40%;
  float: left;
  margin-bottom: 20px;
}

.space-for-logo {
  width: 200px;
  height: 100px;
}

#page-counter {
  display: table-footer-group;
}

#page-counter:after {
  counter-increment: page;
  content: " " counter(page) " of " counter(page);
}
</style>

<template>
  <div>
    <div class="page-break" :key="idx" v-for="(data, idx) in datas">
      <table class="" style="width: 100%">
        <thead>
          <tr class="">
            <th colspan="7">
              <div class="title">
                <p>PICKING LIST</p>
                <p>{{ data.company_name }}</p>
              </div>
            </th>
          </tr>
          <tr class="">
            <th class="py-0 w-32">Cabang</th>
            <th class="py-0 w-1">:</th>
            <th class="py-0 w-32">
              {{ data.warehouse_code }} {{ data.warehouse_name }}
            </th>
            <th class="py-0 w-32"></th>
            <th class="py-0 w-5">Print</th>
            <th class="py-0 w-1" colspan="2">: {{ data.print_counter }}</th>
          </tr>
          <tr>
            <th class="py-0 w-32">Shipment Plan</th>
            <th class="py-0 w-1">:</th>
            <th class="py-0 w-32">{{ data.shipment_number }}</th>
            <th class="py-0 w-32"></th>
            <th class="py-0 w-5">Print date</th>
            <th class="py-0 w-1" colspan="2">: {{ dateNow }}</th>
          </tr>
          <tr>
            <th class="py-0 w-32">Tanggal Shipment</th>
            <th class="py-0 w-1">:</th>
            <th class="py-0 w-32">{{ formatDate(data.shipment_date) }}</th>
            <th class="py-0 w-32"></th>
            <th class="py-0 w-5">Print time</th>
            <th class="py-0 w-1" colspan="2">: {{ timeNow }}</th>
          </tr>
          <tr>
            <th class="py-0 w-32">Driver</th>
            <th class="py-0 w-1">:</th>
            <th class="py-0 w-32">
              {{ data.driver_name }}
            </th>
            <th class="py-0 w-32"></th>
            <th class="py-0 w-5">Page</th>
            <th class="py-0 w-1" colspan="2"><div id="page-counter">:</div></th>
          </tr>
          <tr>
            <th class="py-0 w-32">Vehicle Number</th>
            <th class="py-0 w-1">:</th>
            <th class="py-0 w-32">{{ data.vehicle_number }}</th>
            <th class="py-0 w-32"></th>
            <th class="py-0 w-5"></th>
            <th class="py-0 w-1"></th>
            <th class="py-0 w-5"></th>
          </tr>
        </thead>
      </table>

      <br />
      <table class="" style="width: 100%">
        <thead>
          <tr style="border-top: 1px dashed; border-bottom: 1px dashed">
            <th style="vertical-align: middle; text-align: left">SKU Code</th>
            <th style="vertical-align: middle; text-align: left">SKU Name</th>
            <th style="vertical-align: middle; text-align: left">BATCH</th>
            <th style="vertical-align: middle; text-align: left">ED</th>
            <th style="vertical-align: middle; text-align: left">QTY</th>
            <th style="vertical-align: middle; text-align: left">UOM</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(tr, indexTr) in data.lines">
            <div
              v-bind:key="`margin-${indexTr}-${indexTr2}`"
              style="margin-top: 5px"
            ></div>
            <template v-for="(tr2, indexTr2) in tr.line_summaries">
              <tr v-bind:key="`outerTable-${indexTr}-${indexTr2}`">
                <td class="py-1">{{ tr2.sku_code }}</td>
                <td class="py-1">{{ tr2.item_name }}</td>
                <td class="py-1">{{ tr2.batch_external }}</td>
                <td class="py-1">{{ formatDate(tr2.expired_date) }}</td>
                <td class="py-1">{{ tr2.amount }}</td>
                <td class="py-1">{{ tr2.unit }}</td>
              </tr>
            </template>

            <template v-for="(tr3, indexTr3) in tr.line_summary_total">
              <tr
                v-bind:key="`outerTable2-${indexTr}-${indexTr3}`"
                style="font-weight: bold"
              >
                <td
                  v-if="indexTr3 == 0"
                  colspan="3"
                  :rowspan="tr.line_summary_total.length"
                ></td>
                <td
                  v-if="indexTr3 == 0"
                  class="py-1"
                  :rowspan="tr.line_summary_total.length"
                  :style="
                    indexTr3 == 0
                      ? 'text-align: center;border-top: 1px dashed'
                      : 'text-align: center;'
                  "
                >
                  Total
                </td>
                <td
                  class="py-1"
                  :style="indexTr3 == 0 ? 'border-top: 1px dashed' : ''"
                >
                  {{ tr3.quantity }}
                </td>
                <td
                  class="py-1"
                  :style="indexTr3 == 0 ? 'border-top: 1px dashed' : ''"
                >
                  {{ tr3.unit_name }}
                </td>
              </tr>
            </template>
          </template>
        </tbody>
        <tfoot>
          <tr style="border-top: 1px dashed">
            <td colspan="6"></td>
          </tr>
        </tfoot>
      </table>

      <br />
      <table class="remove_border">
        <tr>
          <th style="text-align: center">Dibuat oleh</th>
          <th style="text-align: center">Disiapkan oleh</th>
          <th style="text-align: center">Diterima oleh</th>
        </tr>
        <tr>
          <th style="height: 50px"></th>
          <th style="height: 50px"></th>
          <th style="height: 50px"></th>
        </tr>
        <tr>
          <th style="text-align: center">Admin Logistic</th>
          <th style="text-align: center">Picker</th>
          <th style="text-align: center">Checker</th>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
  name: "PrintPdf",
  // props: {
  //     handover_id: Number
  // },
  data() {
    return {
      id: null,
      handoverIds: null,
      // table: [],
      sto_plan_code: null,
      picking_code: null,
      selectedSrcWarehouse: null,
      selectedDestWarehouse: null,
      reqDeliveryDate: null,
      eta: null,
      // logoSDN: logoSDN,
      showInfo: false,
      // data: {},
      datas: [],
      dateNow: "",
      timeNow: "",
    };
  },
  mounted() {
    // console.log("opener", window.opener.handoverIds)
    var now = moment();
    now.add(1, "days");

    this.reqDeliveryDate = now.toDate();
    this.eta = now.toDate();

    this.handoverIds = window.opener.handoverIds;
    if (this.handoverIds.length) {
      // this.getData()
      this.print();
    }
  },
  methods: {
    async print() {
      const tempTitle = document.title;
      await this.getData();
      await window.print();
      document.title = tempTitle;
    },
    async getData() {
      this.$vs.loading();
      let resp = await this.$http.get(
        `/api/wms/v1/simple-outbound-planner/picking-data-by-handover2`,
        { params: { handover_ids: this.handoverIds, is_print: 1 } }
      );
      if (resp.code == 200) {
        this.datas = resp.data;
        document.title = this.datas.reduce(
          (prev, curr) => `${prev}-${curr.shipment_number}`,
          "picking_list"
        );
        const now = new Date();
        this.dateNow = this.formatDate(now.setHours(now.getHours() + 7));
        this.timeNow = this.formatTime(now);
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
  },
  computed: {
    formatDate: () => {
      return (val) => {
        const momentDate = moment.utc(val);
        // momentDate.add(1, 'days');              // Tambahkan 1 hari
        return momentDate.format("DD/MM/YYYY");
      };
    },
    formatTime: () => {
      return (val) => {
        const momentDate = moment.utc(val);
        return momentDate.format("hh:mm:ss");
      };
    },
  },
};
</script>
